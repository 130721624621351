




















































































import {
  defineComponent,
  onMounted,
  ref,
  computed,
} from "@vue/composition-api";
import NotificationModalContent from "./NotificationModalContent.vue";

export default defineComponent({
  name: "Notification",
  components: { NotificationModalContent },
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  setup(props, { root }) {
    const notificationItem = computed(() => JSON.parse(props.content));
    const showNotification = ref(true);
    const modalName = "NotificationModal";

    function showModal() {
      root.$modal.show(
        NotificationModalContent,
        {
          notificationData: notificationItem.value.data,
          modalName,
        },
        { name: modalName, height: "auto" }
      );
    }

    function checkIfNotificationIsChecked() {
      const tstamp = localStorage.getItem("bu_notfication-checked");

      if (tstamp != notificationItem.value.data.tstamp) {
        localStorage.removeItem("bu_notfication-checked");
        showNotification.value = true;
      } else {
        showNotification.value = false;
      }
    }

    function closeNotification() {
      localStorage.setItem(
        "bu_notfication-checked",
        notificationItem.value.data.tstamp
      );

      showNotification.value = false;
    }

    onMounted(() => {
      checkIfNotificationIsChecked();
    });

    return {
      notificationItem,
      showNotification,
      closeNotification,
      modalName,
      showModal,
    };
  },
});
