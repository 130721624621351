import Vue from "@/helper/initVue";
import Notification from "@/components/Notification.vue";

const wrapperElements = document.querySelectorAll(".v-notification__wrapper");

Array.from(wrapperElements).forEach((wrapper) => {
  const notificationElement = wrapper.querySelector(".v-notification");

  if (notificationElement) {
    const dataContent = notificationElement.getAttribute("data-content") ?? "";

    new Vue({
      el: notificationElement,
      render: (h) =>
        h(Notification, {
          props: {
            content: dataContent,
          },
        }),
    });
  }
});
