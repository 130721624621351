






































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "NotificationModalContent",
  props: {
    notificationData: {
      type: Object,
      default: "",
    },
    modalName: {
      type: String,
      default: "NotificationModalContent",
    },
  },
  setup(props, { root }) {
    function close() {
      root.$modal.hide(props.modalName);
    }

    return {
      close,
    };
  },
});
